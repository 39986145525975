
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * If true, displays the successful state message
     * otherwise the unsuccessful state message
     */
    successful: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /**
     * If true, displays the success status message
     * Triggered from the parent componenet
     */
    showSuccessMsg: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ["update:showSuccessMsg"],
  data: () => ({
    /**
     * If true, displays the success status message
     * Triggered from the parent componenet
     */
    showMsg: false,
  }),
  computed: {
    successMsg() {
      return this.successful
        ? this.$t("shared.saveSuccessful")
        : this.$t("shared.saveUnsuccessful");
    },
    show() {
      return (this.showMsg = this.showSuccessMsg);
    },
  },
  methods: {
    close(): void {
      this.showMsg = false;
      this.$emit("update:showSuccessMsg", false);
    },
  },
})
export default class SuccessStatusMsg extends Vue {}
