
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import SuccessStatusMsg from "@/components/SuccessStatusMsg.vue";

@Options({
  components: {
    SuccessStatusMsg,
  },
  props: {
    /**
     * Whether the save button should be disabled
     */
    saveBtnDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ["onSaveClicked", "onCancelClicked", "update:editEnable"],
  data: () => ({
    /**
     * If edit-mode is enabled or not
     */
    editEnable: false,
    /**
     * If true, displays the save status message
     * Triggered from the parent componenet
     */
    showSuccessMsg: false,
    /**
     * Whether the save operation was successful
     */
    saveSuccessful: false,
  }),
  methods: {
    /**
     * Triggers save function in parent component
     */
    save(): void {
      this.$emit("onSaveClicked");
    },
    /**
     * Activates edit mode
     */
    edit(): void {
      this.$emit("update:editEnable", true);
      this.setEditEnable(true);
    },
    /**
     * Cancels edit mode
     */
    cancel(): void {
      this.$emit("onCancelClicked");
      this.setEditEnable(false);
    },
    /**
     * Sets editEnable and hides the current success message
     */
    setEditEnable(editEnable: boolean): void {
      this.editEnable = editEnable;
      this.showSuccessMsg = false;
      this.showUnsuccessfulMsg = false;
    },
    /**
     * Triggered from the parent component
     */
    showSuccessStatus(saveSuccessful: boolean): void {
      this.saveSuccessful = saveSuccessful;
      this.showSuccessMsg = true;
      this.editEnable = false;
    },
  },
})
export default class EditButtonsGroup extends Vue {}
