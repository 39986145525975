import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-497603bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "d-flex justify-content-start align-items-center" }
const _hoisted_5 = ["disabled", "required", "value", "aria-label"]
const _hoisted_6 = ["value", "selected"]
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_9 = {
  key: 0,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("select", {
          class: "form-control custom-select",
          disabled: _ctx.disabled,
          required: _ctx.required,
          value: _ctx.value,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
          "aria-label": _ctx.label ? _ctx.label : '',
          ref: "input",
          onInvalid: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null
          ))
        }, [
          (_ctx.placeholder)
            ? (_openBlock(), _createElementBlock("option", {
                key: 0,
                value: _ctx.placeholderValue,
                disabled: "",
                selected: !_ctx.options.includes(_ctx.value)
              }, _toDisplayString(_ctx.placeholder), 9, _hoisted_6))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.id,
              value: option.id,
              selected: option === _ctx.value
            }, [
              _renderSlot(_ctx.$slots, "default", { value: option }, () => [
                _createTextVNode(_toDisplayString(option.name), 1)
              ], true)
            ], 8, _hoisted_7))
          }), 128))
        ], 40, _hoisted_5),
        (_ctx.enableReset)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _createVNode(_component_font_awesome_icon, {
                class: "icon",
                icon: 'undo',
                title: _ctx.$t('shared.reset'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetSelected()))
              }, null, 8, ["title"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}