import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2961bac3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = {
  class: "modal-dialog my-0 mx-auto",
  role: "document"
}
const _hoisted_3 = { class: "modal-content border-0 rounded-0" }
const _hoisted_4 = { class: "nav border-bottom p-3 flex-row justify-content-between align-items-center sticky-top bg-white" }
const _hoisted_5 = {
  id: "modal-header",
  class: "h3 m-0"
}
const _hoisted_6 = { class: "p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("nav", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "header", { data: _ctx.data }, undefined, true)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", { data: _ctx.data }, undefined, true),
            _createElementVNode("button", {
              class: "btn btn-danger confirmBtn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onConfirm()))
            }, _toDisplayString(_ctx.$t("shared.confirm")), 1),
            _createElementVNode("button", {
              class: "btn btn-outline-secondary ml-1 cancelBtn",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
            }, _toDisplayString(_ctx.$t("shared.cancel")), 1)
          ])
        ])
      ])
    ], 512), [
      [_vShow, _ctx.shown]
    ])
  ]))
}