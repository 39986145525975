
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import { INameAndId } from "@/interfaces/INameAndId";

@Options({
  props: {
    /**
     * The select options
     */
    options: {
      type: Array as PropType<Array<INameAndId>>,
      required: true,
    },
    /**
     * The value of the current option
     */
    value: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
    /**
     * The name of the current option
     */
    name: {
      type: [String, Number] as PropType<string | number>,
      required: false,
    },
    /**
     * The label for the select input
     */
    label: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The place holder text for the select input
     */
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The place holder value for the select input
     */
    placeholderValue: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    /**
     * The help text for the select input
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the select input is disabled
     */
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If set to true, a reset button is displayed beside the select list
     */
    enableReset: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ["update:value", "update:name", "selectionChanged"],
  methods: {
    /**
     * Gets called when the user selects an option
     *
     * @param {string} value The value of the selected option
     */
    updateValue(value: string): void {
      const id = parseInt(value);

      const selectedOptionText = this.options.find(
        (option: INameAndId) => option.id === id
      ).name;

      this.emitAndResetValidityMessage(id, selectedOptionText);
    },
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
    /**
     * Resets the selected option to place holder
     */
    resetSelected(): void {
      this.emitAndResetValidityMessage(0, "");
    },
    /**
     * Gets called when the user selects an option
     *
     * @param {number} id The value of the selected option
     * @param {string} name The text of the selected option
     */
    emitAndResetValidityMessage(id: number, name: string): void {
      this.$emit("update:value", id);
      this.$emit("update:name", name);
      this.$emit("selectionChanged", { id: id, name: name });

      this.setValidityMessage("");
    },
  },
})
export default class SelectItem extends Vue {}
