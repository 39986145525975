import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e13c39e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detailsRow d-flex" }
const _hoisted_2 = { class: "detailsCol" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessStatusMsg = _resolveComponent("SuccessStatusMsg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SuccessStatusMsg, {
      successful: _ctx.saveSuccessful,
      showSuccessMsg: _ctx.showSuccessMsg,
      "onUpdate:showSuccessMsg": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSuccessMsg) = $event))
    }, null, 8, ["successful", "showSuccessMsg"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.editEnable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              title: _ctx.$t('course-occurrence.edit-btn-title'),
              id: "editBtn",
              type: "button",
              class: "btn btn-outline-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit()))
            }, _toDisplayString(_ctx.$t("shared.edit")), 9, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.editEnable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              id: "cancelBtn",
              type: "button",
              class: "btn btn-outline-primary mr-2",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
            }, _toDisplayString(_ctx.$t("shared.cancel")), 1))
          : _createCommentVNode("", true),
        (_ctx.editEnable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              "background-color": "btn btn-primary",
              id: "saveBtn",
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save())),
              disabled: _ctx.saveBtnDisabled
            }, _toDisplayString(_ctx.$t("shared.save")), 9, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}